import React from "react"
import { Link as GatsbyLink } from "gatsby"

export default function Link({ children, to, classNames, id }) {
  let path = to

  return (
    <GatsbyLink id={id} className={classNames} to={path}>
      {children}
    </GatsbyLink>
  )
}
