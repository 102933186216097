import { googlePlayLink, itunesLink } from "../../constants/links"

import AppStoreBtn from "../../images/app-store-en.svg"
import Container from "../container/container"
import GooglePlayBtn from "../../images/google-play-en.svg"
import Link from "../link/link"
import LogoFooter from "../../images/logo-footer.svg"
import React from "react"
import styles from "./footer.module.css"
import { useTranslation } from "react-i18next"

export default function Footer({
  pageFaqUrl = "/faq/",
  pageTermsUrl = "/terms/",
  pagePrivacyUrl = "/privacy/",
}) {
  const { t } = useTranslation()

  const helpUrl = "/help/"

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerWrapper}>
          <Link to="/" classNames={styles.footerLogoLink}>
            <img
              src={LogoFooter}
              alt="Smartpass"
              className={styles.footerLogoImg}
            />
          </Link>
          <nav id="footer-links" className={styles.footerLinks}>
            <Link to={helpUrl} classNames={styles.footerLinksItem}>
              {t("footer.help")}
            </Link>
            <Link to={pageFaqUrl} classNames={styles.footerLinksItem}>
              {t("footer.faq")}
            </Link>
            <Link to={pageTermsUrl} classNames={styles.footerLinksItem}>
              {t("footer.terms")}
            </Link>
            <Link to={pagePrivacyUrl} classNames={styles.footerLinksItem}>
              {t("footer.privacy")}
            </Link>
            <a
              href="https://www.gasolinamovil.com/security/"
              rel="noreferrer"
              className={styles.footerLinksItem}
            >
              {t("footer.security")}
            </a>
          </nav>
        </div>
        <div className={styles.footerWrapper}>
          <nav className={styles.footerDownloadButtons}>
            <a href={itunesLink} className={styles.footerDownloadButtonsButton}>
              <img
                src={AppStoreBtn}
                alt="Smartpass App Store"
                className={styles.footerDownloadButtonsButtonImg}
              />
            </a>
            <a
              href={googlePlayLink}
              className={styles.footerDownloadButtonsButton}
            >
              <img
                src={GooglePlayBtn}
                alt="Smartpass Google Play"
                className={styles.footerDownloadButtonsButtonImg}
              />
            </a>
          </nav>
          <p className={styles.footerCopy}>
            © {new Date().getFullYear()} &nbsp;
            <span className={styles.footerCopyLink}>Alias Payments</span>, Inc.
            All Rights Reserved.
          </p>
        </div>
      </Container>
    </footer>
  )
}
