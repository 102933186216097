import React, { useEffect, useState } from "react"

import Link from "../link/link"
import MenuButton from "../../images/menu-button.png"
import MenuButtonClose from "../../images/X.png"
import TopLogo from "../../images/top-logo.svg"
import styles from "./header.module.css"
import { useTranslation } from "react-i18next"

export default function Header({
  classVariant,
  location,
  logo = TopLogo,
  pageFaqUrl = "/faq/",
}) {
  const [isMobile, setIsMobile] = useState(false)

  const { t } = useTranslation()

  const toggleMenu = () => {
    document.body.classList.toggle("fixed")
    setIsMobile(!isMobile)
  }

  useEffect(() => {
    document.body.classList.remove("fixed")
  }, [])

  const helpUrl = "/help/"

  return (
    <header
      id="header"
      className={`${styles.header} ${
        classVariant ? styles[classVariant] : ""
      } ${isMobile ? styles.headerMobileActive : ""}`}
    >
      <Link to="/" classNames={styles.headerLogoLink}>
        <img src={logo} alt="Smartpass" className={styles.headerLogoImg} />
      </Link>
      <nav
        id="header__menu"
        className={`${styles.headerMenu} ${
          isMobile ? styles.headerMenuActive : ""
        }`}
      >
        <button
          className={styles.headerMenuButton}
          onClick={toggleMenu}
          tabIndex={0}
        >
          <img
            src={MenuButton}
            alt="Smartpass"
            className={`${styles.headerMenuButtonImg} ${styles.headerMenuButtonImgOpen}`}
          />
          <img
            src={MenuButtonClose}
            alt="Smartpass"
            className={`${styles.headerMenuButtonImg} ${styles.headerMenuButtonImgClose}`}
          />
        </button>
        <Link
          to="/"
          id="menu-link-home"
          classNames={`${styles.headerMenuLink} ${styles.headerMenuLinkHome}`}
        >
          {t("header.home")}
        </Link>
        <a
          href={helpUrl}
          id="menu-link-services"
          className={styles.headerMenuLink}
        >
          {t("header.help")}
        </a>
        <Link
          to={pageFaqUrl}
          id="menu-link-questions"
          classNames={styles.headerMenuLink}
        >
          {t("header.faq")}
        </Link>
      </nav>
    </header>
  )
}
